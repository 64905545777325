<template>
  <div>
    <div class="head-wrap mx-auto mt-40">
      <p class="font-bold head-title text-black leading-none">Saved Videos and Notes</p>
      <p class="base-font-gray-200 text-2xl mt-6">
        These are the Discovery Jobs that you did in the past. 
        You can use this page to see the progress of your current 
        jobs or see the results of the ones you did in the past.
      </p>
    </div>
    <div class="container-custom mx-auto">
      <div class="flex flex-wrap mt-20">
        <div class="flex flex-wrap items-center justify-between w-full">
          <Title 
            title="Saved Videos"
            class="w-full"
            headClasses="pl-4"
            alighY="items-end"
          >
            <template #sort>
              <span class="text-sm text-black flex cursor-pointer">
                Show all labels
                <img src="../assets/icons/chevron-top.svg" class="ml-4"/>
              </span>
            </template>
          </Title>
          <ul class="w-full">
            <li class="px-4 py-4 flex flex-wrap items-center justify-between w-full savedVideoLine border-b border-dashed border-grey-darker-200">
              <div class="flex flex-wrap items-center">
                <div class="relative mr-6">
                  <img src="../assets/images/savedVideo.png" />
                  <img src="../assets/images/savedVideoIcon.png" class="absolute top-1/2 right-0 savedVideoIcon"/>
                </div>
                <p>
                  <span class="block font-mono text-sm base-font-gray-light-400 uppercase">VIDEO TITLE</span>
                  <span class="block base-font-gray-200 text-base">Makin’ Moves - Ep. 14 - Movin’ With The Shark</span>
                </p>
              </div>
              <div class="flex flex-wrap items-center pt-2">
                <Tags 
                  :tags="['Woozuh']"
                  :showClose="false"
                />
                <div class="flex items-center justify-center bg-white rounded-full w-7 h-7 ml-1 mb-2 savedVideoLineClose opacity-0 invisible cursor-pointer">
                  <img src="../assets/icons/close-icon.svg"/>
                </div>
              </div>
            </li>
          </ul>

          <Pagination />
        </div>
      </div>
      <div class="flex flex-wrap mt-12">
        <div class="flex flex-wrap items-center justify-between w-full mb-24">
          <Title 
            title="Notes"
            class="w-full relative"
            headClasses="pl-4"
            alighY="items-end"
          >
            <template #sort>
              <span 
                class="text-sm text-black flex cursor-pointer"
                @click="setNotesFilterList"
              >
                Show all type of notes
                <img v-if="isNotesFilterList" src="../assets/icons/chevron-bottom.svg" class="ml-4"/>
                <img v-else src="../assets/icons/chevron-top.svg" class="ml-4"/>
              </span>
              <ul 
                v-if="isNotesFilterList"
                class="bg-grey rounded px-4 absolute right-0 top-full"
              >
                <li 
                  v-for="item in notesFilterList"
                  :key="item.value"
                  class="py-1 border-b border-dashed border-grey-lighter whitespace-nowrap cursor-pointer"
                  @click="setNotesFilterList(item.value)"
                >
                  {{ item.name }}
                </li>
              </ul>
            </template>
          </Title>

          <NotesList :notes="videoNotes"/>

          <Pagination />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Title from '@/components/Title'
import Tags from '@/components/Tags'
import Pagination from '@/components/Pagination'
import NotesList from '@/components/NotesList'

export default {
  name: 'Saved',
  components: {
    Title,
    Tags,
    Pagination,
    NotesList
  },
  data(){
    return{
      isNotesFilterList: false,
      notesFilter: '',
      notesFilterList: [
        {
          name: 'Show all type of Notes',
          value: 'all'
        },
        {
          name: 'Show Private Notes',
          value: 'private'
        },
        {
          name: 'Show Team A Notes',
          value: 'teamA'
        },
        {
          name: 'Show Team B Notes',
          value: 'teamB'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      videoNotes: 'videoDetails/videoNotes'
    })
  },  
  methods: {
    ...mapActions({
      getDirectVideoNotes: 'videoDetails/getDirectVideoNotes',
    }),
    setNotesFilterList(arg){
      if(arg){
        this.notesFilter = arg
      }
      this.isNotesFilterList = !this.isNotesFilterList
    }
  },
  async mounted() {
    await this.getDirectVideoNotes({ 
      id: '2L7yLB3P8Xg', 
      limit: 20 // doesn`t work now
    })
  }
};
</script>

<style lang="scss" scoped>
.savedVideoIcon{
  transform: translateY(-50%) translateX(50%);
}
.savedVideoLine{
  &:hover{
    background-color: #F8F6FF;
    .savedVideoLineClose{
      opacity: 1;
      visibility: visible;
    }
  }
}
</style>